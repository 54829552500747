<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Ver Incidencia"
                        :class="activeStep >= 0 ? activeClass : ''"
                      >
                        <span>Ver Incidencia</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <!--single form panel-->
                    <h5 class="font-weight-bolder mb-0">Ver Fotos de Incidencia</h5>
                    <div class="row mt-3">
                      <div class="col-12 col-sm-6 d-flex mt-4">
                        <img class="shadow-sm w-100 border-radius-lg"
                             v-for="(foto, index) in imagenes"
                             :key="index"
                             :src="this.url_aws+'/Incidencias/'+foto"
                             style="margin-right: 35px;"/>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 col-sm-6 d-flex mt-4">
                        <h5 class="font-weight-bolder mb-0">Descripción:  </h5>{{ item.descripcion}}
                      </div>
                    </div>
                    <div v-if="error !== ''">
                      <label
                          type="error"
                          :value="alert"
                          class="alert-warning text-bold"
                      >
                        {{ error }}
                      </label>
                    </div>
                    <div class="button-row d-flex mt-6">
                      <material-button
                          type="button"
                          color="danger"
                          variant="gradient"
                          class="js-btn-send"
                          @click="getIncidenciasList()"
                      >Cancelar</material-button
                      >
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";


export default {
  name: "ViewIncidencia",
  components: {
    MaterialButton,
  },
  data() {
    return {
      url_aws: 'https://s3-integration-store-qa.s3.amazonaws.com',
      showMenu: false,
      imagenes: [],
      showChangePass: false,
      changePassword: null,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      error: '',
      alert: false,
      idItem: -1,
      item: {}
    };
  },
  watch :{
    "$store.state.incidencia": function(incidencia) {
      this.item = incidencia;
      this.imagenes = this.dividirString(this.item.fotos);
      console.log("incidencia: ", this.item);
    },
    '$store.state.ok': function (value) {
      console.log("state.ok: ", value)
      if (value){
        this.$router.push({ path: "/report/incidencias/list" });
      }
    },
    '$store.state.errorMessage': function (message) {
      this.error = message;
      this.alert = true;
    }
  },
  methods: {
    dividirString(fotos) {
      return fotos.split(' | ');
    },
    getIncidenciasList(){
      this.$router.push({ path: "/report/incidencias/list" });
    },
  },
  async mounted() {
    this.idItem = await this.$route.params.id !== undefined ? this.$route.params.id : -1;
    await this.$store.dispatch('actionsGetIncidencia', {
      'axios': this.$axios,
      'id': this.idItem
    })
  }
};
</script>
<style>
.form-label {
  position: relative;
  display: inline-block;
}


.form-label span {
  position: absolute;
  left: 5px;
  top: 0;
  transition: 0.2s;
  pointer-events: none;
}

.form-label select:focus + span,
.form-label select:not(:placeholder-shown) + span {
  transform: translateY(-15px);
  font-size: 0.8em;
}
</style>
