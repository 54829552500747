<template>
  <footer class="footer">
    <div class="footer-content">
      ©
      S3 Integración.
<!--      {{ new Date().getFullYear() }} -->
      Todos los derechos reservados. Desarrollado por
            <a
                href="https://www.idoogroup.com"
                class="font-weight-bold"
                target="_blank"
            >
              Idoogroup.
            </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
<style scoped>
.footer {
  //position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: black;
  text-align: center;
}

.footer-content {
  padding: 20px;
}
</style>
