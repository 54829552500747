<template>
  <div class="py-4 container-fluid">
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row mb-4 px-4 py-1 container-fluid d-flex justify-content-between">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="button-row d-flex mt-4">
              <span class="me-3 ms-auto bold">Filtrar tablas:</span>
              <material-button
                  type="button"
                  color="success"
                  variant="success"
                  class="js-btn-send me-3 ms-auto"
                  :active="is_active"
                  @click="showDiv('diario')"
              >Diario</material-button
              >
              <material-button
                  type="button"
                  color="success"
                  variant="success"
                  class="js-btn-send me-3 ms-auto"
                  :active="is_active"
                  @click="showDiv('semanal')"
              >Semanal</material-button
              >
              <material-button
                  type="button"
                  color="success"
                  variant="success"
                  class="js-btn-send me-3 ms-auto"
                  :active="is_active"
                  @click="showDiv('mensual')"
              >Mensual</material-button
              >
              <material-button
                  type="button"
                  color="success"
                  variant="success"
                  class="js-btn-send me-3 ms-auto"
                  :active="is_active"
                  @click="showDiv('anual')"
              >Anual</material-button
              >
            </div>
          </div>
        </div>

        <div class="row mb-4 px-4 py-3 container-fluid">
          <div v-show="currentDiv === 'diario'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Escaneos"
                :subtitle="'Día: ' + dayOfWeekMethods()"
            >
              <reports-bar-chart-day
                  :height="170"
                  :key="keyScanD"
                  :id="id_escaneosD"
                  :chart="chartDataScanDaily"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'semanal'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Escaneos"
                :subtitle="'Fecha: '+ getWeek()"
            >
              <reports-bar-chart
                  :height="170"
                  :key="keyScanS"
                  :id="id_escaneosS"
                  :chart="chartDataScan"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'mensual'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Escaneos"
                :subtitle="'Mes: '+ getMonthString()"
            >
              <reports-line-chart
                  :height="5"
                  :key="keyScanM"
                  :id="id_escaneosM"
                  :chart="chartDataScanMontly"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'anual'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" >
            <chart-holder-card
                title="Escaneos"
            >
              <reports-line-chart
                  :key="keyScanA"
                  :id="id_escaneosA"
                  :chart="chartDataScanAnual"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'diario'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Impresiones"
                :subtitle="'Día: ' + dayOfWeekMethods()"
            >
              <reports-bar-chart-day
                  :height="170"
                  :key="keyImpressionD"
                  :id="id_impresionesD"
                  :chart="chartDataImpressionDaily"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'semanal'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Impresiones"
                :subtitle="'Fecha: '+ getWeek()"
            >
              <reports-bar-chart
                  :key="keyImpressionS"
                  :id="id_impresionesS"
                  :chart="chartDataImpression"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'mensual'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Impresiones"
                :subtitle="'Mes: '+ getMonthString()"
            >
              <reports-line-chart
                  :height="5"
                  :key="keyImpressionM"
                  :id="id_impresionesW"
                  :chart="chartDataImpresionesMontly"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'anual'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" >
            <chart-holder-card
                title="Impresiones"
            >
              <reports-line-chart
                  :key="keyImpressionA"
                  :id="id_impresionesA"
                  :chart="chartDataImpresionesAnual"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'diario'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Etiquetas"
                :subtitle="'Día: ' + dayOfWeekMethods()"
            >
              <reports-bar-chart-day
                  :height="170"
                  :key="keyEtiquetasD"
                  :id="id_etiquetasD"
                  :chart="chartDataEtiquetasDaily"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'semanal'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Etiquetas"
                :subtitle="'Fecha: '+ getWeek()"
            >
              <reports-bar-chart
                  :key="keyEtiquetasW"
                  :id="id_etiquetasW"
                  :chart="chartDataEtiquetas"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'mensual'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Etiquetas"
                :subtitle="'Mes: '+ getMonthString()"
            >
              <reports-line-chart
                  :height="5"
                  :key="keyEtiquetasM"
                  :id="id_etiquetasS"
                  :chart="chartDataEtiquetasMontly"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'anual'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" >
            <chart-holder-card
                title="Etiquetas"
            >
              <reports-line-chart
                  :key="keyEtiquetasA"
                  :id="id_etiquetasA"
                  :chart="chartDataEtiquetasAnual"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'diario'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Incidencias"
                :subtitle="'Día: ' + dayOfWeekMethods()"
            >
              <reports-bar-chart-day
                  :height="170"
                  :key="keyIncidenciasD"
                  :id="id_incidenciasD"
                  :chart="chartDataIncidenciasDaily"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'semanal'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Incidencias"
                :subtitle="'Fecha: '+ getWeek()"
            >
              <reports-bar-chart
                  :key="keyIncidenciasW"
                  :id="id_incidenciasS"
                  :chart="chartDataIncidencias"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'mensual'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <chart-holder-card
                title="Incidencias"
                :subtitle="'Mes: '+ getMonthString()"
            >
              <reports-line-chart
                  :height="5"
                  :key="keyIncidenciasW"
                  :id="id_incidenciasM"
                  :chart="chartDataIncidenciasMontly"
              />
            </chart-holder-card>
          </div>
          <div v-show="currentDiv === 'anual'" class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4" >
            <chart-holder-card
                title="Incidencias"
            >
              <reports-line-chart
                  :key="keyIncidenciasA"
                  :id="id_incidenciasA"
                  :chart="chartDataIncidenciasAnual"
              />
            </chart-holder-card>
          </div>

        </div>
        <div class="row mb-4 px-4 py-1 container-fluid d-flex justify-content-between">
          <div class="card border-radius-lg col-lg-6 col-md-5 col-sm-5 mt-lg-0 mt-4">
            <h6 class="mb-0 font-weight-bolder">Paquetes</h6>
            <div id="table1" class="table-responsive">
              <table id="order-list" class="table table-flush">
                <thead class="thead-light">
                <tr>
                  <th>Número Rastreo</th>
                  <th>Cantidad</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in orders" :key="item.id">
                  <td>{{ item.carrierTracking }}</td>
                  <td>{{ item.itemQuantity }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card border-radius-lg col-lg-6 col-md-5 col-sm-5 mt-lg-0 mt-4" style="position: revert">
            <h6 class="mb-0 font-weight-bolder">Incidencias</h6>
            <div id="table2" class="table-responsive">
              <table id="incidencias-list" class="table table-flush">
                <thead class="thead-light">
                <tr>
                  <th>Numero de Órden</th>
                  <th>Fecha</th>
                  <th>Estado</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in incidencias" :key="item.id">
                  <td>{{ item.order.orderNumber }}</td>
                  <td>{{ dateHumanization(item.date) }}</td>
                  <td>
                    <chip-card v-if="item.status === 'DENEGADA'" color="red" text="Denegada" />
                    <chip-card v-else-if="item.status === 'APROBADA'" color="green" text="Aprobada" />
                    <chip-card v-else color="blue" text="Pendiente" />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <slot />
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <mini-statistics-card
                :title="{ text: 'Órdenes', value: countItems.ordenes }"
                detail="<span class='text-success text-sm font-weight-bolder'>+55%</span> than last week"
                :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'dark',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
                :title="{ text: 'Etiquetas', value: countItems.etiquetas }"
                detail="<span class='text-success text-sm font-weight-bolder'>+3%</span> than last month"
                :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'primary',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
                :title="{ text: 'Incidencias', value: countItems.incidencias }"
                detail="<span class='text-success text-sm font-weight-bolder'>+1%</span> than yesterday"
                :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
                :title="{ text: 'Reportes', value: countItems.reportes }"
                detail="Just updated"
                :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'info',
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import US from "../../assets/img/icons/flags/US.png";
import DE from "../../assets/img/icons/flags/DE.png";
import GB from "../../assets/img/icons/flags/GB.png";
import BR from "../../assets/img/icons/flags/BR.png";
import booking1 from "../../assets/img/products/product-1-min.jpg";
import booking2 from "../../assets/img/products/product-2-min.jpg";
import booking3 from "../../assets/img/products/product-3-min.jpg";
import {DataTable} from "simple-datatables";
import Utils from "@/utils/Utils";
import MaterialButton from "@/components/MaterialButton.vue";
import ChipCard from "@/views/ecommerce/components/ChipCard.vue";
import ReportsLineChart from "@/views/dashboards/components/ReportsLineChart.vue";
import ReportsBarChartDay from "@/examples/Charts/ReportsBarChartDay.vue";
// import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "DashboardDefault",
  components: {
    // Carousel,
    // Slide,
    ReportsBarChartDay,
    ReportsLineChart,
    ChipCard,
    MaterialButton,
    ChartHolderCard,
    ReportsBarChart,
    MiniStatisticsCard,
  },
  data() {
    return {
      booking1,
      booking2,
      booking3,
      US,
      DE,
      GB,
      BR,
      lbs: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      lbs_month: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
      lbs_day: this.getDayOfMonthString(),
      is_active: false,
      currentDiv: null,
      id_escaneosA: this.generateKey(),
      id_escaneosD: this.generateKey(),
      id_escaneosS: this.generateKey(),
      id_escaneosM: this.generateKey(),
      id_impresionesA: this.generateKey(),
      id_impresionesD: this.generateKey(),
      id_impresionesS: this.generateKey(),
      id_impresionesW: this.generateKey(),
      id_etiquetasS: this.generateKey(),
      id_etiquetasW: this.generateKey(),
      id_etiquetasA: this.generateKey(),
      id_etiquetasD: this.generateKey(),
      id_incidenciasA: this.generateKey(),
      id_incidenciasS: this.generateKey(),
      id_incidenciasD: this.generateKey(),
      id_incidenciasM: this.generateKey(),
      countItems: {},
      orders: [],
      incidencias: [],
      isLoading: false,
      apiDataScan : {},
      apiDataImpression : {},
      apiDataEtiquetas : {},
      apiDataIncidencias : {},

      apiDataScanMontly : {},
      apiDataImpressionMontly : {},
      apiDataEtiquetasMontly : {},
      apiDataIncidenciasMontly : {},

      apiDataEtiquetasAnual: {},
      apiDataIncidenciasAnual: {},
      apiDataScanAnual: {},
      apiDataImpresionAnual: {},
      totalsByWeekday: {},
      totalsByMonths: {},
      totalsByDays: [],
      totalsArray: [],
      totalsArrayImpression: [],
      totalsArrayScan: [],
      totalDayliScan: 0,
      totalDayliImpression: 0,
      totalDayliEtiquetas: 0,
      totalDayliIncidencias: 0,
      totalsArrayEtiquetas: [],
      totalsArrayIncidencias: [],
      totalsArrayEtiquetasAnnual: [],
      totalsArrayScanAnnual: [],
      totalsArrayImpressionsAnnual: [],
      totalsArrayIncidenciasAnnual: [],

      totalsArrayEtiquetasMontly: [],
      totalsArrayScanMontly: [],
      totalsArrayImpressionsMontly: [],
      totalsArrayIncidenciasMontly: [],
      now: new Date(),
      start_date: null,
      end_date: null,
      keyEtiquetasW: this.generateKey(),
      keyEtiquetasA: this.generateKey(),
      keyEtiquetasM: this.generateKey(),
      keyEtiquetasD: this.generateKey(),
      keyImpressionA: this.generateKey(),
      keyImpressionS: this.generateKey(),
      keyImpressionD: this.generateKey(),
      keyImpressionM: this.generateKey(),
      keyScanA: this.generateKey(),
      keyScanM: this.generateKey(),
      keyScanS: this.generateKey(),
      keyScanD: this.generateKey(),
      keyIncidenciasA: this.generateKey(),
      keyIncidenciasS: this.generateKey(),
      keyIncidenciasD: this.generateKey(),
      keyIncidenciasW: this.generateKey(),
      chartDataScan: {
        labels: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        datasets: {
          label: 'Escaneos',
          data: this.totalsArrayScan,
        },
      },
      chartDataScanMontly: {
        labels: this.getDayOfMonthString(),
        datasets: {
          label: 'Escaneos',
          data: [50,1,2,63,65,32,30,56,63,65,50,1,2,63,65,50,1,2,63,65,50,1,2,63,65,50,1,2,63,65],
        },
      },
      chartDataIncidenciasMontly: {
        labels: this.getDayOfMonthString(),
        datasets: {
          label: 'Incidencias',
          data: [50,1,2,63,65,32,30,56,63,65,50,1,2,63,65,50,1,2,63,65,50,1,2,63,65,50,1,2,63,65],
        },
      },
      chartDataEtiquetasMontly: {
        labels: this.getDayOfMonthString(),
        datasets: {
          label: 'Etiquetas',
          data: [50,1,2,63,65,32,30,56,63,65,50,1,2,63,65,50,1,2,63,65,50,1,2,63,65,50,1,2,63,65],
        },
      },
      chartDataImpresionesMontly: {
        labels: this.getDayOfMonthString(),
        datasets: {
          label: 'Impresiones',
          data: [50,1,2,63,65,32,30,56,63,65,50,1,2,63,65,50,1,2,63,65,50,1,2,63,65,50,1,2,63,65],
        },
      },
      chartDataScanDaily: {
        labels: [this.dayOfWeekMethods()],
        datasets: {
          label: 'Escaneos',
          data: [this.totalDayliScan],
        },
      },
      chartDataImpressionDaily: {
        labels: [this.dayOfWeekMethods()],
        datasets: {
          label: 'Impresiones',
          data: [this.totalDayliImpression],
        },
      },
      chartDataEtiquetasDaily: {
        labels: [this.dayOfWeekMethods()],
        datasets: {
          label: 'Etiquetas',
          data: [this.totalDayliEtiquetas],
        },
      },
      chartDataIncidenciasDaily: {
        labels: [this.dayOfWeekMethods()],
        datasets: {
          label: 'Incidencias',
          data: [this.totalDayliIncidencias],
        },
      },
      chartDataEtiquetas: {
        labels: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        datasets: {
          label: 'Etiquetas',
          data: this.totalsArrayEtiquetas,
        },
      },
      chartDataIncidencias: {
        labels: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        datasets: {
          label: 'Incidencias',
          data: this.totalsArrayIncidencias,
        },
      },
      chartDataImpression: {
        labels: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        datasets: {
          label: 'Impresiones',
          data: this.totalsArrayImpression,
        },
      },
      chartDataScanAnual: {
        labels: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
        datasets: {
          label: 'Escaneos',
          data: this.totalsArrayScanAnnual,
        },
      },
      chartDataIncidenciasAnual: {
        labels: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
        datasets: {
          label: 'Incidencias',
          data: this.totalsArrayIncidenciasAnnual,
        },
      },
      chartDataImpresionesAnual: {
        labels: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
        datasets: {
          label: 'Impresiones',
          data: this.totalsArrayImpressionsAnnual,
        },
      },
      chartDataEtiquetasAnual: {
        labels: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
        datasets: {
          label: 'Etiquetas',
          data: this.totalsArrayEtiquetasAnnual,
        },
      },
    }
  },
  // escaneos, impresiones, etiquetas, incidencias
  watch: {
    async totalsArrayImpressionsMontly(newTotalsArrayImpression) {
      await this.$nextTick(() => {
        this.chartDataImpresionesMontly.datasets.data = newTotalsArrayImpression;
      });
      this.keyImpressionM = this.generateKey();
    },
    async totalsArrayScanMontly(newTotalsArrayScan) {
      await this.$nextTick(() => {
        this.chartDataScanMontly.datasets.data = newTotalsArrayScan;
      });
      this.keyScanM = this.generateKey();
    },
    async totalsArrayIncidenciasMontly(newTotalsArrayIncidencias) {
      await this.$nextTick(() => {
        this.chartDataIncidenciasMontly.datasets.data = newTotalsArrayIncidencias;
      });
      this.keyIncidenciasW = this.generateKey();
    },
    async totalsArrayEtiquetasMontly(newTotalsArrayLabel) {
      await this.$nextTick(() => {
        this.chartDataEtiquetasMontly.datasets.data = newTotalsArrayLabel;
      });
      this.keyEtiquetasM = this.generateKey();
    },


    async totalsArrayImpression(newTotalsArrayImpression) {
      await this.$nextTick(() => {
        this.chartDataImpression.datasets.data = newTotalsArrayImpression;
      });
      this.keyImpressionS = this.generateKey();
    },
    async totalsArrayScan(newTotalsArrayScan) {
      await this.$nextTick(() => {
        this.chartDataScan.datasets.data = newTotalsArrayScan;
      });
      this.keyScanS = this.generateKey();
    },
    async totalsArrayIncidencias(newTotalsArrayIncidencias) {
      await this.$nextTick(() => {
        this.chartDataIncidencias.datasets.data = newTotalsArrayIncidencias;
      });
      this.keyIncidenciasW = this.generateKey();
    },
    async totalsArrayEtiquetas(newTotalsArrayLabel) {
      await this.$nextTick(() => {
        this.chartDataEtiquetas.datasets.data = newTotalsArrayLabel;
      });
      this.keyEtiquetasW = this.generateKey();
    },
    async totalsArrayEtiquetasAnnual(newTotalsArrayLabel) {
      await this.$nextTick(() => {
        this.chartDataEtiquetasAnual.datasets.data = newTotalsArrayLabel;
      });
      this.keyEtiquetasA = this.generateKey();
    },
    async totalsArrayScanAnnual(newTotalsArrayLabel) {
      await this.$nextTick(() => {
        this.chartDataScanAnual.datasets.data = newTotalsArrayLabel;
      });
      this.keyScanA = this.generateKey();
    },
    async totalsArrayImpressionsAnnual(newTotalsArrayLabel) {
      await this.$nextTick(() => {
        this.chartDataImpresionesAnual.datasets.data = newTotalsArrayLabel;
      });
      this.keyImpressionA = this.generateKey();
    },
    async totalsArrayIncidenciasAnnual(newTotalsArrayLabel) {
      await this.$nextTick(() => {
        this.chartDataIncidenciasAnual.datasets.data = newTotalsArrayLabel;
      });
      this.keyIncidenciasA = this.generateKey();
    },
    async totalDayliScan(value) {
      await this.$nextTick(() => {
        this.chartDataScanDaily.datasets.data = [value];
        console.log("DailyScan: ", value);
      });
      this.keyScanD = this.generateKey();
    },
    async totalDayliImpression(value) {
      await this.$nextTick(() => {
        this.chartDataImpressionDaily.datasets.data = [value];
        console.log("DailyImpression: ", value);

      });
      this.keyImpressionD = this.generateKey();
    },
    async totalDayliEtiquetas(value) {
      await this.$nextTick(() => {
        this.chartDataEtiquetasDaily.datasets.data = [value];
      });
      this.keyEtiquetasD = this.generateKey();
    },
    async totalDayliIncidencias(value) {
      await this.$nextTick(() => {
        this.chartDataIncidenciasDaily.datasets.data = [value];
        console.log("DailyIncidencias: ", value);

      });
      this.keyIncidenciasD = this.generateKey();
    },
    '$store.state.countItems': function (array){
      this.countItems = array
      console.log("this.countItems: ", this.countItems);
    },
    '$store.state.scanWeeklyTotal': function (array){
      this.apiDataScan = array
      this.apiDataScan.forEach(item => {
        this.totalsByWeekday[item.weekday] = item.total;
      });
      this.totalsArrayScan = this.lbs.map(day => this.totalsByWeekday[day] || 0);
      this.totalsByWeekday = [];
    },
    '$store.state.impressionWeeklyTotal': function (array){
      this.apiDataImpression = array
      this.apiDataImpression.forEach(item => {
        this.totalsByWeekday[item.weekday] = item.total;
      });
      this.totalsArrayImpression = this.lbs.map(day => this.totalsByWeekday[day] || 0);
      this.totalsByWeekday = [];
    },
    '$store.state.etiquetasWeeklyTotal': function (array){
      this.apiDataEtiquetas = array
      this.apiDataEtiquetas.forEach(item => {
        this.totalsByWeekday[item.weekday] = item.total;
      });
      this.totalsArrayEtiquetas = this.lbs.map(day => this.totalsByWeekday[day] || 0);
      this.totalsByWeekday = [];
    },
    '$store.state.incidenciasWeeklyTotal': function (array){
      this.apiDataIncidencias = array
      this.apiDataIncidencias.forEach(item => {
        this.totalsByWeekday[item.weekday] = item.total;
      });
      this.totalsArrayIncidencias = this.lbs.map(day => this.totalsByWeekday[day] || 0);
      this.totalsByWeekday = [];
    },
    '$store.state.orderList': function (orders) {
      this.orders = orders;
      console.log("orders: ", this.orders);
      if (this.datatableOrder) {
        this.datatableOrder.destroy();
      }
      this.$nextTick(() => {
        this.datatableOrder = new DataTable("#order-list", {
          searchable: true,
          fixedHeight: false,
          perPage: 6,
          perPageSelect: [2, 4, 8, 10],
          labels: {
            placeholder: "Buscar...", // Placeholder del campo de búsqueda
            perPage: "{select} registros por página", // Entradas por página
            noRows: "No hay registros",
            noResults: "No se encontraron registros",// Mensaje cuando no hay entradas,
            info: "Mostrando {start} a {end} de {rows} entradas", // Información de las entradas mostradas
          }
        });

        document.querySelector('.dataTable-dropdown').addEventListener('change', (event) => {
          console.log('El usuario ha seleccionado:', event.target.value, 'elementos por página');
        });

        document.querySelector('.dataTable-pagination').addEventListener('click', (event) => {
          if (event.target.tagName === 'A') {
            console.log('El usuario está navegando a la página:', event.target.textContent);
            // Verifica si el usuario ha navegado a la última página
            if (event.target.textContent == this.datatable.pages.length) {
              console.log('El usuario ha navegado a la última página');
            }
          }
        });
        let inputElement = document.querySelector(".dataTable-search input");
        let iconElement = document.createElement("i");
        iconElement.className = "fas fa-search";
        inputElement.parentNode.appendChild(iconElement);
        inputElement.placeholder = "Buscar...";
        inputElement.parentNode.style.position = "relative";
        iconElement.style.position = "absolute";
        iconElement.style.top = "50%";
        iconElement.style.right = "10px";
        iconElement.style.transform = "translateY(-50%)";

        let table = document.querySelector("#table1");

        let dropdownElement = table.querySelector(".dataTable-dropdown");
        let infoElement = table.querySelector(".dataTable-info");
        let paginationElement = table.querySelector(".dataTable-pagination");
        let bottomElement = table.querySelector(".dataTable-bottom");
        dropdownElement.parentNode.removeChild(dropdownElement);
        infoElement.parentNode.removeChild(infoElement);
        paginationElement.parentNode.removeChild(paginationElement);
        bottomElement.appendChild(infoElement);
        bottomElement.appendChild(dropdownElement);
        bottomElement.appendChild(paginationElement);
        bottomElement.style.display = "flex";
        bottomElement.style.justifyContent = "space-between";

      }).then(() => {
        // this.isLoading = false;
      });
    },
    '$store.state.incidenciasList': function (incidencias) {
      console.log("incidencias: ", incidencias);

      this.incidencias = incidencias;
      if (this.datatableIncidencias) {
        this.datatableIncidencias.destroy();
      }
      this.$nextTick(() => {
        this.datatableIncidencias = new DataTable("#incidencias-list", {
          searchable: true,
          fixedHeight: false,
          perPage: 6,
          perPageSelect: [2, 4, 8, 10],
          labels: {
            placeholder: "Buscar...", // Placeholder del campo de búsqueda
            perPage: "{select} registros por página", // Entradas por página
            noRows: "No hay registros", // Mensaje cuando no hay entradas
            noResults: "No se encontraron registros",// Mensaje cuando no hay entradas
            info: "Mostrando {start} a {end} de {rows} entradas", // Información de las entradas mostradas
          }
        });

        document.querySelector('.dataTable-dropdown').addEventListener('change', (event) => {
          console.log('El usuario ha seleccionado:', event.target.value, 'elementos por página');
        });

        document.querySelector('.dataTable-pagination').addEventListener('click', (event) => {
          if (event.target.tagName === 'A') {
            console.log('El usuario está navegando a la página:', event.target.textContent);
            if (event.target.textContent  === this.datatable.pages.length) {
              console.log('El usuario ha navegado a la última página');
            }
          }
        });
        let inputElement = document.querySelector(".dataTable-search input");
        let iconElement = document.createElement("i");
        iconElement.className = "fas fa-search";
        inputElement.parentNode.appendChild(iconElement);
        inputElement.placeholder = "Buscar...";
        inputElement.parentNode.style.position = "relative";
        iconElement.style.position = "absolute";
        iconElement.style.top = "50%";
        iconElement.style.right = "10px";
        iconElement.style.transform = "translateY(-50%)";

        let table = document.querySelector("#table2");

        let dropdownElement = table.querySelector(".dataTable-dropdown");
        let infoElement = table.querySelector(".dataTable-info");
        let paginationElement = table.querySelector(".dataTable-pagination");
        let bottomElement = table.querySelector(".dataTable-bottom");
        dropdownElement.parentNode.removeChild(dropdownElement);
        infoElement.parentNode.removeChild(infoElement);
        paginationElement.parentNode.removeChild(paginationElement);
        bottomElement.appendChild(infoElement);
        bottomElement.appendChild(dropdownElement);
        bottomElement.appendChild(paginationElement);
        bottomElement.style.display = "flex";
        bottomElement.style.justifyContent = "space-between";

      }).then(() => {
        this.isLoading = false;
      });
    },
    '$store.state.etiquetasAnnualTotal': function (array){
      this.apiDataEtiquetasAnual = array
      this.apiDataEtiquetasAnual.forEach(item => {
        this.totalsByMonths[item.monthNumber] = item.total;
      });
      this.totalsArrayEtiquetasAnnual = this.lbs_month.map((month, index) => {
        return this.totalsByMonths[index + 1] || 0;
      });
      this.totalsByMonths = [];
    },
    '$store.state.scanAnnualTotal': function (array){
      this.apiDataScanAnual = array
      this.apiDataScanAnual.forEach(item => {
        this.totalsByMonths[item.monthNumber] = item.total;
      });
      this.totalsArrayScanAnnual = this.lbs_month.map((month, index) => {
        return this.totalsByMonths[index + 1] || 0;
      });
      this.totalsByMonths = [];
    },
    '$store.state.impresionesAnnualTotal': function (array){
      this.apiDataImpresionAnual = array
      this.apiDataImpresionAnual.forEach(item => {
        this.totalsByMonths[item.monthNumber] = item.total;
      });
      this.totalsArrayImpressionsAnnual = this.lbs_month.map((month, index) => {
        return this.totalsByMonths[index + 1] || 0;
      });
      this.totalsByMonths = [];
    },
    '$store.state.incidenciasAnnualTotal': function (array){
      this.apiDataIncidenciasAnual = array
      this.apiDataIncidenciasAnual.forEach(item => {
        this.totalsByMonths[item.monthNumber] = item.total;
      });
      this.totalsArrayIncidenciasAnnual = this.lbs_month.map((month, index) => {
        return this.totalsByMonths[index + 1] || 0;
      });
      this.totalsByMonths = [];
    },
    '$store.state.incidenciasDaily': function (value){
      this.totalDayliIncidencias = value;
    },
    '$store.state.scanDaily': function (value){
      this.totalDayliScan = value;
    },
    '$store.state.impresionesDaily': function (value){
      this.totalDayliImpression = value;
    },
    '$store.state.etiquetasDaily': function (value){
      this.totalDayliEtiquetas = value;
    },

    '$store.state.scanMontlyTotal': function (array){
      this.apiDataScanMontly = array
      this.apiDataScanMontly.forEach(item => {
        this.totalsByDays[item.day] = item.total;
      });
      this.totalsArrayScanMontly = this.lbs_day.map(day => this.totalsByDays[day] || 0);
      this.totalsByDays = [];
    },
    '$store.state.impressionMontlyTotal': function (array){
      this.apiDataImpressionMontly = array
      this.apiDataImpressionMontly.forEach(item => {
        this.totalsByDays[item.day] = item.total;
      });
      this.totalsArrayImpressionsMontly = this.lbs_day.map(day => this.totalsByDays[day] || 0);
      this.totalsByDays = [];
    },
    '$store.state.etiquetasMontlyTotal': function (array){
      this.apiDataEtiquetasMontly = array
      this.apiDataEtiquetasMontly.forEach(item => {
        this.totalsByDays[item.day] = item.total;
      });
      this.totalsArrayEtiquetasMontly = this.lbs_day.map(day => this.totalsByDays[day] || 0);
      this.totalsByDays = [];
    },
    '$store.state.incidenciasMontlyTotal': function (array){
      this.apiDataIncidenciasMontly = array
      this.apiDataIncidenciasMontly.forEach(item => {
        this.totalsByDays[item.day] = item.total;
      });
      this.totalsArrayIncidenciasMontly = this.lbs_day.map(day => this.totalsByDays[day] || 0);
      this.totalsByDays = [];
    },

  },

  methods: {
    getMonthString() {
      let date = new Date();
      let month = date.getMonth();

      return month;
    },
    getDayOfMonthString() {
      let date = new Date();
      let month = date.getMonth();
      let year = date.getFullYear();
      let daysInMonth = new Date(year, month + 1, 0).getDate();

      let daysArray = [];
      for (let i = 1; i <= daysInMonth; i++) {
        daysArray.push(i);
      }
      return daysArray;
    },
    getWeek() {
      let weekRange = '';
      let now = new Date();
      let dayOfWeek = now.getDay(); // 0 (domingo) - 6 (sábado)
      let numDay = now.getDate();

      let start = new Date(now);
      start.setDate(numDay - dayOfWeek);

      let end = new Date(now);
      end.setDate(numDay + (6 - dayOfWeek));

      function formatDate(date) {
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // 0 (enero) 11 (diciembre)
        let year = date.getFullYear();
        return day + '/' + month + '/' + year;
      }

      weekRange = formatDate(start) + '-' + formatDate(end);
      return weekRange;
    },
    dayOfWeekMethods(){
      let date = new Date();
      let day = date.getDay();
      let days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
      return days[day];
    },
    showDiv(divName) {
      this.currentDiv = divName;
    },
    generateKey() {
      return Math.random().toString(36).substring(2);
    },
    dateHumanization(date) {
      return Utils.dateHumanization(date);
    },
  },
  async created() {
    this.getDayOfMonthString();
    this.isLoading = true;
    this.$store.dispatch('actionsGetAllOrders', {
          'axios': this.$axios,
          'page': 1,
          'pageSize': 10
        }
    );
    this.$store.dispatch('actionsGetAllIncidencias', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsGetCountItems', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsImpressionWeeklyTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsScanWeeklyTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsEtiquetasWeeklyTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsIncidenciasWeeklyTotal', {
          'axios': this.$axios,
        }
    );

    this.$store.dispatch('actionsImpressionMontlyTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsScanMontlyTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsEtiquetasMontlyTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsIncidenciasMontlyTotal', {
          'axios': this.$axios,
        }
    );

    this.$store.dispatch('actionsEtiquetasAnnualTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsImpressionAnnualTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsScanAnnualTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsIncidenciasAnnualTotal', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsIncidenciasDaily', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsEtiquetasDaily', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsImpressionsDaily', {
          'axios': this.$axios,
        }
    );
    this.$store.dispatch('actionsScanDaily', {
          'axios': this.$axios,
        }
    );
    this.orders = await this.$store.getters.getAllOrders;
    this.countItems = await this.$store.getters.getCountItems;
    this.apiDataImpression = await this.$store.getters.getImpressionWeeklyTotal;
    this.apiDataScan = await this.$store.getters.getScanWeeklyTotal;
    this.apiDataIncidencias = await this.$store.getters.getIncidenciasWeeklyTotal;
    this.apiDataEtiquetas = await this.$store.getters.getEtiquetasWeeklyTotal;
    this.apiDataEtiquetasAnual = await this.$store.getters.getEtiquetasAnualTotal;
    this.apiDataImpresionAnual = await this.$store.getters.getImpressionAnualTotal;
    this.apiDataIncidenciasAnual = await this.$store.getters.getIncidenciasAnualTotal;
    this.apiDataScanAnual = await this.$store.getters.getScanAnualTotal;
    this.incidencias = await this.$store.getters.getAllIncidencias;
    this.totalDayliScan = await this.$store.getters.getScanDaily;
    this.totalDayliImpression = await this.$store.getters.getImpressionDaily;
    this.totalDayliEtiquetas = await this.$store.getters.getEtiquetasDaily;
    this.totalDayliIncidencias = await this.$store.getters.getIncidenciasDaily;


    this.apiDataImpressionMontly = await this.$store.getters.getImpressionMontlyTotal;
    this.apiDataScanMontly = await this.$store.getters.getScanMontlyTotal;
    this.apiDataIncidenciasMontly = await this.$store.getters.getIncidenciasMontlyTotal;
    this.apiDataEtiquetasMontly = await this.$store.getters.getEtiquetasMontlyTotal;


    this.currentDiv = 'semanal';
  }
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite; /* Safari */
  animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.d-flex {
  display: flex;
}
.card {
  margin-bottom: 10px; /* Ajusta este valor a tu gusto */
}
</style>
