import moment from "moment";

export default class Utils {
  static async counItems(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          countItems{
            ordenes
            incidencias
            reportes
            etiquetas
          }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.countItems;
  }


  static async scanWeeklyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          scanWeeklyTotal{
            day
            weekday
            total
          }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.scanWeeklyTotal;
  }

  static async impressionWeeklyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          impressionWeeklyTotal{
            day
            weekday
            total
          }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.impressionWeeklyTotal;
  }
  static async etiquetasWeeklyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          etiquetasWeeklyTotal{
            day
            weekday
            total
          }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.etiquetasWeeklyTotal;
  }
  static async incidenciasWeeklyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          incidenciasWeeklyTotal{
            day
            weekday
            total
          }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.incidenciasWeeklyTotal;
  }

  static async etiquetasAnnualTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          etiquetasAnnualTotal{
            monthNumber
            monthName
            total
        }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.etiquetasAnnualTotal;
  }

  static async incidenciasAnnualTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          incidenciasAnnualTotal{
            monthNumber
            monthName
            total
        }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.incidenciasAnnualTotal;
  }
  static async impressionAnnualTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          impressionAnnualTotal{
            monthNumber
            monthName
            total
        }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.impressionAnnualTotal;
  }
  static async scanAnnualTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          scanAnnualTotal{
            monthNumber
            monthName
            total
        }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    return response.data.data?.scanAnnualTotal;
  }
  static async scanDailyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          scanDailyTotal
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    console.log("scanDailyTotal: ", response.data.data?.scanDailyTotal);
    return response.data.data?.scanDailyTotal;
  }
  static async etiquetasDailyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          etiquetasDailyTotal
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    console.log("etiquetasDailyTotal: ", response.data.data?.etiquetasDailyTotal);

    return response.data.data?.etiquetasDailyTotal;
  }
  static async incidenciasDailyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          incidenciasDailyTotal
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    console.log("incidenciasDailyTotal: ", response.data.data?.incidenciasDailyTotal);
    return response.data.data?.incidenciasDailyTotal;
  }
  static async impressionDailyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          impressionDailyTotal
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    console.log("impressionDailyTotal: ", response.data.data?.impressionDailyTotal);
    return response.data.data?.impressionDailyTotal;
  }

  static async scanMonthlyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          scanMonthlyTotal{
            day
            total
          }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    console.log("scanMonthlyTotal: ", response.data.data?.scanMonthlyTotal);
    return response.data.data?.scanMonthlyTotal;
  }
  static async etiquetasMonthlyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
            etiquetasMonthlyTotal{
              day
              total
            }
          }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    console.log("etiquetasDailyTotal: ", response.data.data?.etiquetasMonthlyTotal);

    return response.data.data?.etiquetasMonthlyTotal;
  }
  static async incidenciasMonthlyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          incidenciasMonthlyTotal{
            day
            total
          }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    console.log("incidenciasMonthlyTotal: ", response.data.data?.incidenciasMonthlyTotal);
    return response.data.data?.incidenciasMonthlyTotal;
  }
  static async impressionMonthlyTotal(axios) {
    const response = await axios?.post(
        "/graphql/",
        `query{
          impressionMonthlyTotal{
            day
            total
          }
        }`,
        {
          headers: {
            "Content-Type": "application/graphql",
          },
        }
    );
    console.log("impressionMonthlyTotal: ", response.data.data?.impressionMonthlyTotal);
    return response.data.data?.impressionMonthlyTotal;
  }

  static generateString() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 11; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static hasPermissionToView(currentUser, permissions) {
    return this.hasPermission(currentUser, permissions, 'view')
  }

  static hasPermissionToCreate(currentUser, permissions) {
    return this.hasPermission(currentUser, permissions, 'add')
  }

  static hasPermissionToUpdate(currentUser, permissions) {
    return this.hasPermission(currentUser, permissions, 'change')
  }

  static hasPermissionToDelete(currentUser, permissions) {
    return this.hasPermission(currentUser, permissions, 'delete')
  }

  static dateHumanization(date) {
    moment.locale('es');
    return moment(date).format('D MMMM YYYY')
  }

  static dateHumanizationSeparator(date) {
    moment.locale('es');
    if (typeof date === 'undefined' || date === null) {
      return;
    }
    if (date.includes('|')) {
      const [date1, date2] = date.split('|').map(date => date.trim());
      return [moment(date1).format('D MMMM YYYY'), moment(date2).format('D MMMM YYYY')];
    } else {
      return moment(date).format('D MMMM YYYY'); // Si no hay separador, se formatea la fecha directamente
    }
  }

  static dateTimeHumanization(date) {
    moment.locale('es');
    return moment(date).format('D MMMM YYYY, h:mm:ss a')
  }

  static formatDateTime(datetime) {
    moment.locale('es');
    return moment(String(datetime)).format('DD/MM/YYYY | HH:mm');
  }

  static formatDate(date) {
    moment.locale('es');
    return moment(String(date)).format('DD/MM/YYYY');
  }

  static priceHumanization(price, large) {
    if (price > 0){
      if (large) {
        return '$' + (price / 100).toFixed(2).toString().replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",") + ' MXN'
      } else {
        return '$' + price.toFixed(2).toString().replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",") + ' MXN'
      }
    } else {
      return '$ 0.00 MXN'
    }
  }

  static email(email) {
    return `mailto:${email}`
  }

  static last4Humanization(last4) {
    return last4 !== null ? '**** **** **** ' + last4 : '---'
  }

  static percentHumanization(percent) {
    return percent.toFixed(2).toString() + ' %'
  }

  static hasPermission(currentUser, permissions, action) {
    if (currentUser !== null && currentUser !== undefined) {
      if (currentUser.isStaff) return true;
      else {
        let has = false;
        let currentUserGroups = currentUser.groups;

        for (let i = 0; i < permissions.length && !has; i++) {
          for (let j = 0; j < currentUserGroups.length && !has; j++) {
            if(currentUserGroups[j].permissions)
            for (let k = 0; k < currentUserGroups[j].permissions.length && !has; k++) {
              if (currentUserGroups[j].permissions[k].name.includes(action) && currentUserGroups[j].permissions[k].name.includes(permissions[i])) {
                has = true
              }
            }
          }
        }
        return has;
      }
    }
    return false;
  }

  static isEmployment(currentUser) {
    if (currentUser !== null && currentUser !== undefined) {
      if (currentUser.employment !== null) {
        return currentUser.employment.branchOffice.id;
      }
    }
    return -1;
  }

  static isManager(currentUser) {
    if (currentUser !== null && currentUser !== undefined)
    if (currentUser.manager !== null) return currentUser.manager.company !== null ? currentUser.manager.company.id : -1;

    return -1;
  }

  static isAdmin(currentUser) {
    if (currentUser !== null && currentUser !== undefined) {
       return currentUser.isStaff;
    }
    return false;
  }

  static getTotalCouponAssigned(couponList) {
    var total = 0;

    for(var i=0; i < couponList.length; i++) {
      if(couponList[i].coupons !== null) {
          total += couponList[i].totalUses * couponList[i].coupons.maximumSales;
      }
    }

    return total;
  }

  static getTotalPaymentServices(services) {
    var total = 0.00;

    for(var i=0; i < services.length; i++) {
        total += services[i].price;
    }

    return total;
  }

  static getDistinct(list) {
    var distinct = [];

    for(var i=0; i < list.length; i++) {
        if(!distinct.includes(list[i])) distinct.push(list[i]);
    }

    return distinct;
  }

  static thousands(price) {
     return '$' + price.toLocaleString('en-US', {minimumFractionDigits: 2}) + ' MXN';
  }

}
