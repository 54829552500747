<template>
  <div class="container-fluid py-4">
    <div class="row" v-if="ok != null">
      <material-alert
          border="top"
          close-icon="$cancel"
          :color="colorAlert()"
          transition="scale-transition"
          :value="ok"
          dismissible
      >
        {{ textAlert() }}
      </material-alert>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-5 col-lg-5 m-auto">
              <div class="card">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-danger shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Eliminar Perfil de Impresión"
                        :class="activeStep >= 0 ? activeClass : ''"
                      >
                        <span>Eliminar Perfil de Impresión</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body" style="display: flex; justify-content: center; align-items: center;">
                  <form class="multisteps-form__form">
                    <!--single form panel-->
                    <h4 class="font-weight-bolder mb-0">Eliminar Perfil de Impresión</h4>
                    <h5 class="font-weight-bolder mb-0">Nombre : {{ item.name}}</h5>
                    <div class="multisteps-form__content">
                      <div v-if="error !== ''">
                        <label
                            type="error"
                            :value="alert"
                            class="alert-warning text-bold"
                        >
                          {{ error }}
                        </label>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <material-button
                            type="button"
                            color="danger"
                            variant="danger"
                            class="js-btn-send me-3 ms-auto"
                            @click="getRoutePrintingProfileList()"
                        >Cancelar</material-button
                        >
                        <material-button
                            type="button"
                            color="success"
                            variant="gradient"
                            class="ms-auto mb-0 js-btn-send"
                            @click="deletePrintingProfile()"
                        >Confirmar</material-button
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";


export default {
  name: "DeletePrintingProfile",
  components: {
    MaterialAlert,
    MaterialButton,
  },
  data() {
    return {
      showMenu: false,
      idItem: -1,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      error: '',
      ok: null,
      alert: false,
      item: {
        username: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        area: '',
        password: '',
        repeatPassword: ''
      }
    };
  },
  watch :{
    "$store.state.printingProfile": function(user) {
      this.item = user;
      console.log("printingProfile: ", this.item)
    },
    '$store.state.ok': function (value) {
      console.log("state.ok: ", value)
      if (value){
        this.ok = true;
        setTimeout(this.getRoutePrintingProfileList, 100);
      }
    },
    '$store.state.errorMessage': function (message) {
      this.error = message;
      this.alert = true;
    }
  },
  methods: {
    textAlert() {
      return this.ok
          ? "Perfil de Impresión eliminado satisfactoriamiente"
          : "Error al intentar eliminar el Perfil de Impresión";
    },
    colorAlert() {
      return this.ok ? "success" : "danger";
    },
    getRoutePrintingProfileList(){
      this.$router.push({ path: "/printingProfile/list" });
    },
    deletePrintingProfile() {
      this.$store.dispatch('actionsDeletePrintingProfile', {
        axios: this.$axios,
        id: this.item.id,
      });
    },
  },
  created() {
    this.idItem = this.$route.params.id !== undefined ? this.$route.params.id : -1;
    this.$store.dispatch('actionsGetPrintingProfile', {
      'axios': this.$axios,
      'id': this.idItem
    })
  }
};
</script>
<style>
.form-label {
  position: relative;
  display: inline-block;
}


.form-label span {
  position: absolute;
  left: 5px;
  top: 0;
  transition: 0.2s;
  pointer-events: none;
}

.form-label input:focus + span,
.form-label input:not(:placeholder-shown) + span {
  transform: translateY(-25px);
  font-size: 0.8em;
}
</style>
