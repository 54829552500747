<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="loading-spinner"></div>
  </div>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div>
        <!--        <material-button color="success" variant="gradient">New order</material-button>-->
      </div>
      <div class="d-flex">
        <material-button
            class="btn-icon ms-2"
            size=""
            color="success"
            variant="gradient"
            data-type="csv"
            @click="onButtonClick"
        >
          <span class="btn-inner--text">Importar CSV</span>
        </material-button>
        <input
            ref="fileInput"
            type="file"
            style="display: none"
            @change="onFileChange"
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header">
            <h5 class="mb-0">Listado de Órdenes</h5>
          </div>
          <div class="table-responsive">
            <div v-if="error !== ''" class="bg-danger mt-auto ms-4 col-6 card">
              <label
                  type="error"
                  :value="alert"
                  class="text-bold text-white"
              >
                {{ error }}
              </label>
            </div>
            <div v-if="message !== ''">
              <label
                  type="error"
                  :value="show_text"
                  class="text-bold d-block me-lg-12 mt-auto ms-4 col-6 bg-success card"
                  style="background-color: lightgreen"
              >
                {{ message }}
              </label>
            </div>
            <div class="table-responsive">
              <table id="order-list" class="table table-flush">
                <thead class="thead-light">
                <tr>
<!--                  <th>No. PO</th>-->
                  <th>No. Órden</th>
                  <th>No. Tracking</th>
                  <th>Nombre Producto</th>
                  <th>Acción</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="item.id">
<!--                  <td>{{ item.poNumber }}</td>-->
                  <td>{{ parseString(item.orderNumber, 10) }}</td>
                  <td>{{ item.carrierTracking }}</td>
                  <td>{{ parseStringTitle(item.title, 60) }}</td>
<!--                  <td>-->
<!--                    <chip-card v-if="item.deliveryStatus === 'Delivered'" color="blue" text="Entregado" />-->
<!--                    <chip-card v-else color="red" text="No Entregado" />-->
<!--                  </td>-->
                  <td>
                    <router-link class="action-link" :to="`/orders/order-details/${item.id}`">
                      <i class="fas fa-eye action color-accent" />
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import Utils from "@/utils/Utils";
// import ChipCard from "@/views/ecommerce/components/ChipCard.vue";


export default {
  name: "OrderList",
  components: {
    // ChipCard,
    MaterialButton,
  },
  data: () => ({
    isLoading: false,
    datatable: null,
    itemsUpload: [],
    showTable: true,
    items: [],
    maxLength: 60,
    error: '',
    message: '',
    show_text: false,
    alert: false,
  }),
  watch: {
    '$store.state.orderList': function (orders) {
      this.items = orders;
      console.log("items: ", this.items);
      if (this.datatable) {
        this.datatable.destroy();
      }
      this.$nextTick(() => {
        this.datatable = new DataTable("#order-list", {
          searchable: true,
          fixedHeight: false,
          perPage: 15,
          perPageSelect: [15, 20, 25, 'Todos'],
          labels: {
            placeholder: "Buscar...", // Placeholder del campo de búsqueda
            perPage: "{select} registros por página", // Entradas por página
            noRows: "No hay registros", // Mensaje cuando no hay entradas.
            noResults: "No se encontraron registros",// Mensaje cuando no hay entradas
            info: "Mostrando {start} a {end} de {rows} entradas", // Información de las entradas mostradas
          }
        });

        document.querySelector('.dataTable-dropdown').addEventListener('change', (event) => {
          if (event.target.value === "Todos") {
            console.log("mostrando todos:", this.items.length);
            this.datatable.options.perPage = this.items.length;
            this.datatable.update();
          }
        });

        document.querySelector('.dataTable-dropdown').addEventListener('change', (event) => {
          console.log('El usuario ha seleccionado:', event.target.value, 'elementos por página');
        });

        document.querySelector('.dataTable-pagination').addEventListener('click', (event) => {
          if (event.target.tagName === 'A') {
            console.log('El usuario está navegando a la página:', event.target.textContent);
            // Verifica si el usuario ha navegado a la última página
            if (event.target.textContent === this.datatable.pages.length) {
              console.log('El usuario ha navegado a la última página');
            }
          }
        });
        let inputElement = document.querySelector(".dataTable-search input");
        let iconElement = document.createElement("i");
        iconElement.className = "fas fa-search";
        inputElement.parentNode.appendChild(iconElement);
        inputElement.placeholder = "Buscar...";
        inputElement.parentNode.style.position = "relative";
        iconElement.style.position = "absolute";
        iconElement.style.top = "50%";
        iconElement.style.right = "10px";
        iconElement.style.transform = "translateY(-50%)";

        let dropdownElement = document.querySelector(".dataTable-dropdown");
        let infoElement = document.querySelector(".dataTable-info");
        let paginationElement = document.querySelector(".dataTable-pagination");
        let bottomElement = document.querySelector(".dataTable-bottom");
        dropdownElement.parentNode.removeChild(dropdownElement);
        infoElement.parentNode.removeChild(infoElement);
        paginationElement.parentNode.removeChild(paginationElement);
        bottomElement.appendChild(infoElement);
        bottomElement.appendChild(dropdownElement);

        bottomElement.appendChild(paginationElement);
        bottomElement.style.display = "flex";
        bottomElement.style.justifyContent = "space-between";
      }).then(() => {
        this.isLoading = false;
      });
    },
  },

  methods: {
    parseString(string, maxLength) {
      let tempArray = string.split(',');
      let result = tempArray[0];

      if (result.length > maxLength) {
        result = result.substring(0, this.maxLength);
        return result + '...'
      } else {
        return tempArray[0] + '...'
      }
    },
    parseStringTitle(string) {
      let tempArray = string.split(',');
      let result = tempArray[0];

      if (result.length > this.maxLength) {
        result = result.substring(0, this.maxLength);
        return result + '...'
      } else {
        return tempArray[0] + '...'
      }
    },
    dateHumanization(date) {
      return Utils.dateHumanization(date);
    },
    onButtonClick() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      let file = e.target.files[0];
      if (file.name.split('.').pop() !== 'csv') {
        console.log('Por favor, selecciona un archivo .csv');
        this.alert = true;
        this.error = 'Error en Archivo: Por favor, selecciona un archivo con extensión .csv'
        this.isLoading = false
        this.$refs.fileInput.value = null;
      } else {
        let formData = new FormData();
        formData.append('file', file);
        this.uploadFile(formData);
        this.isLoading = true
      }
    },
    uploadFile(formData) {
      this.$axios.post('/upload_csv/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(async response => {
        console.log(response.data);
        this.isLoading = false

        if (response.data['status']) {
          this.show_text = true;
          this.message = response.data['message'];
        } else {
          this.show_text = true;
          this.error = response.data['message'];
        }
      }).catch(error => {
        console.error(error);
        this.alert = true;
        this.error = error
        this.isLoading = false
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    this.$store.dispatch('actionsGetAllOrders', {
          'axios': this.$axios,
          'page': 1,
          'pageSize': 500
        }
    );
    this.items = await this.$store.getters.getAllOrders;
  },
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite; /* Safari */
  animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
