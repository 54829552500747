import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import api from './api';
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import DataTable from 'datatables.net-vue3'
import DataTablesLib from 'datatables.net-bs5';
import DataTablesCore from 'datatables.net-bs5';
import Select from 'datatables.net-select-bs5'
import 'datatables.net-select-bs5';
import 'datatables.net-dt';
import 'datatables.net-select';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.vfs;


DataTable.use(DataTablesCore);
DataTable.use(DataTablesLib);
DataTable.use(Select);



const appInstance = createApp(App);
appInstance.config.globalProperties.$axios = api;
appInstance.config.globalProperties.$pdfMake = pdfMake

appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.use(DataTable);
// appInstance.use(PDFPlugin);
appInstance.mount("#app");
