<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="card mb-4">
          <div class="card-header p-3 pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6>Detalles de Orden</h6>
              </div>
            </div>
          </div>
          <div v-if="isLoading" class="loading-overlay">
            <div class="loading-spinner"></div>
          </div>
          <div v-else class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="d-flex">
                  <div>
                    <material-avatar
                      :img="img1"
                      class="me-3"
                      size="xxl"
                      circular
                      alt="product image"
                    />
                  </div>
                  <div>
                    <p><span class="text-lg mb-0 mt-2">{{item.title}}</span></p>
                    <material-badge v-if="item.deliveryStatus === 'Delivered'" color="success" variant="gradient" size="sm">
                      Entregado
                    </material-badge>
                    <material-badge v-else color="danger" variant="gradient" size="sm">
                      No Entregado
                    </material-badge>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12 my-auto text-end">
              </div>
            </div>
            <hr class="horizontal dark mt-4 mb-4" />
            <div class="row">
              <div class="d-flex justify-content-between">
                <div class="col-lg-6 col-md-6 col-12">
                  <h6 class="mb-3">Detalles del Pedido</h6>
                  <div class="d-flex justify-content-start">
                    <span class="mb-2 text-sm font-weight-bold">Carrier Tracking:</span>
                    <span class="text-dark ms-2">{{item.carrierTracking}}</span>
                  </div>
                  <div class="d-flex justify-content-start">
                    <span class="mb-2 text-sm font-weight-bold">Número de Órden:</span>
                    <span class="text-dark ms-2">{{ item.orderNumber }}</span>
                  </div>
                  <div class="d-flex justify-content-start">
                    <span class="text-sm font-weight-bold">Fecha:</span>
                    <span class="text-dark ms-2">{{ dateHumanization(item.orderDate) }}</span>
                  </div>
                </div>

                <div class="col-lg- col-md-6 col-12">
                  <h6 class="mb-3 ms-0">Otros Detalles</h6>
                  <div class="d-flex justify-content-start">
                    <span class="mb-2 text-sm font-weight-bold">Nombre del Carrier:</span>
                    <span class="text-dark ms-2">{{item.carrierName}}</span>
                  </div>
                  <div class="d-flex justify-content-start">
                    <span class="mb-2 text-sm font-weight-bold">Código ASIN::</span>
                    <span class="text-dark ms-2">{{ item.asin }}</span>
                  </div>
                  <div class="d-flex justify-content-start">
                    <span class="text-sm font-weight-bold">Cantidad:</span>
                    <span class="text-dark ms-2">{{ item.itemQuantity  }}</span>
                  </div>
                  <div class="d-flex justify-content-start">
                    <span class="text-sm font-weight-bold">Domicilio de Entrega:</span>
                    <span class="text-dark ms-2">{{ item.shippingAddress }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="button-row d-flex mt-4">
              <material-button
                  type="button"
                  color="danger"
                  variant="danger"
                  class="js-btn-send me-3 ms-auto"
                  @click="getRouteOrderList()"
              >Cancelar</material-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialBadge from "@/components/MaterialBadge.vue";
import img1 from "../../../assets/img/product-12.jpg";
import Utils from "@/utils/Utils";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "OrderDetails",
  components: {
    MaterialButton,
    MaterialAvatar,
    MaterialBadge,
  },
  data() {
    return {
      img1,
      idItem: -1,
      item: {},
      isLoading: null
    };
  },
  watch :{
    "$store.state.order": function(item) {
      this.item = item;
      console.log("Order: ", this.item);
      this.isLoading = false
    },
  },
  methods: {
    dateHumanization(date) {
      return Utils.dateHumanization(date);
    },
    getRouteOrderList(){
      this.$router.push({ path: "/orders/order-list" })
    },
  },
  async created() {
    this.idItem = this.$route.params.id !== undefined ? this.$route.params.id : -1;
    this.$store.dispatch('actionsGetOrder', {
      'axios': this.$axios,
      'id': this.idItem
    });
    this.item = await this.$store.getters.getOrder;
    this.isLoading = true;
  }
};
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite; /* Safari */
  animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>