<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Editar Incidencia"
                        :class="activeStep >= 0 ? activeClass : ''"
                      >
                        <span>Editar Incidencia</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <!--single form panel-->
                    <h5 class="font-weight-bolder mb-0">Cambiar Estado Incidencia</h5>
                    <div class="multisteps-form__content">
                      <div class="row mt-3">
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <select
                                id="status"
                                v-model="item.status"
                                class="form-control border-bottom custom-select"
                                name="status"
                                required
                            >
                              <option v-for="(status, index) in statuses" :key="index" :value="status">{{ status }}</option>
                            </select>
                            <span>Estado Incidencia *</span>
                          </label>
                        </div>
                      </div>
                      <div v-if="error !== ''">
                        <label
                            type="error"
                            :value="alert"
                            class="alert-warning text-bold"
                        >
                          {{ error }}
                        </label>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <material-button
                            type="button"
                            color="danger"
                            variant="danger"
                            class="js-btn-send"
                            @click="getAreasList()"
                        >Cancelar</material-button
                        >
                        <material-button
                            type="button"
                            color="success"
                            variant="gradient"
                            class="ms-auto js-btn-send"
                            @click="editArea()"
                        >Aceptar</material-button
                        >
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";


export default {
  name: "EditIncidencia",
  components: {
    MaterialButton,
  },
  data() {
    return {
      showMenu: false,
      showChangePass: false,
      changePassword: null,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      error: '',
      statuses: {
        APROBADA: 'APROBADA',
        DENEGADA: 'DENEGADA',
        PENDIENTE: 'PENDIENTE',
      },
      alert: false,
      idItem: -1,
      item: {
        name: '',
        status: '',
      }
    };
  },
  computed: {
    getRole() {
      if (this.idItem > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.item = this.$store.getters.getRole;
        return this.item;
      }
      return null
    },
  },
  watch :{
    "$store.state.incidencia": function(incidencia) {
      this.item = incidencia;
      console.log("incidencia: ", this.item)
    },
    '$store.state.ok': function (value) {
      console.log("state.ok: ", value)
      if (value){
        this.$router.push({ path: "/report/incidencias/list" });
      }
    },
    '$store.state.errorMessage': function (message) {
      this.error = message;
      this.alert = true;
    }
  },
  methods: {
    getAreasList(){
      this.$router.push({ path: "/report/incidencias/list" });
    },
    editArea() {
      this.$store.dispatch('actionsEditIncidencia', {
        axios: this.$axios,
        id: this.item.id,
        status: this.item.status,
      });
    },
  },
  created() {
    this.idItem = this.$route.params.id !== undefined ? this.$route.params.id : -1;
    this.$store.dispatch('actionsGetIncidencia', {
      'axios': this.$axios,
      'id': this.idItem
    })
  }
};
</script>
<style>
.form-label {
  position: relative;
  display: inline-block;
}


.form-label span {
  position: absolute;
  left: 5px;
  top: 0;
  transition: 0.2s;
  pointer-events: none;
}

.form-label select:focus + span,
.form-label select:not(:placeholder-shown) + span {
  transform: translateY(-15px);
  font-size: 0.8em;
}
</style>
