<template>
  <div class="container-fluid py-4">
    <div class="row" v-if="ok != null">
      <material-alert
          border="top"
          close-icon="$cancel"
          :color="colorAlert()"
          transition="scale-transition"
          :value="ok"
          dismissible
      >
        {{ textAlert() }}
      </material-alert>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <div class="card">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Nuevo Usuario"
                        :class="activeStep >= 0 ? activeClass : ''"
                      >
                        <span>Nuevo Usuario</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <!--single form panel-->
                    <h5 class="font-weight-bolder mb-0">Información del Usuario</h5>
                    <div class="multisteps-form__content">
                      <div class="row mt-3 py-lg-3">
                        <div class="col-6 col-sm-6">
                          <div class="d-flex">
                            <material-button
                                class="btn-icon ms-2"
                                size=""
                                color="success"
                                variant="gradient"
                                @click.prevent="onButtonClick"
                            >
                              <span class="btn-inner--text">Cambiar Ávatar</span>
                            </material-button>
                            <input
                                id="fileUpload"
                                ref="fileUpload"
                                type="file"
                                style="display: none"
                                @change="previewImage"
                            >
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 mt-3 mt-sm-0">
                          <span>Avatar</span>

                          <label for="file-upload-img" class="form-label col-sm-12">
                            <img id="file-upload-img"
                                 v-if="imageUrl"
                                 class="form-label col-sm-12 avatar"
                                 :src="imageUrl"
                                 alt="Image preview" />
                          </label>

                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-6 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="firstName"
                                v-model="item.firstName"
                                placeholder=" "
                                type="text"
                                class="form-control border-bottom"
                                name="firstName"
                                required
                                autocomplete="off"
                                :maxlength="maxLen"
                                @input="checkLength('firstName', maxLen)"
                            />
                            <span>Nombre *</span>
                          </label>
                        </div>
                        <div class="col-6 col-sm-6 mt-3 mt-sm-0">
                          <label class="form-label  col-sm-12">
                            <input
                                id="lastName"
                                v-model="item.lastName"
                                placeholder=" "
                                type="text"
                                class="form-control border-bottom"
                                name="lastName"
                                required
                                autocomplete="off"
                                :maxlength="maxLen * 3"
                                @input="checkLength('lastName', maxLen * 3)"
                            />
                            <span>Apellidos *</span>
                          </label>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12 col-sm-4">
                          <label class="form-label col-sm-12">
                            <input
                                id="username"
                                v-model="item.username"
                                :type="inputType"
                                placeholder=" "
                                class="form-control border-bottom"
                                name="username"
                                required
                                autocomplete="new-password"
                                :maxlength="maxLen - 2"
                                @input="checkLength('username', maxLen-2)"
                            />
                            <span>Nombre de Usuario *</span>
                          </label>
                        </div>
                        <div class="col-12 col-sm-4">
                          <label class="form-label col-sm-12">
                            <select
                                id="area"
                                v-model="item.area"
                                class="form-control border-bottom custom-select"
                                name="area"
                                required
                            >
                              <option v-for="(area, index) in areas" :key="index" :value="area.id">{{ area.name }}</option>
                            </select>
                            <span>Área *</span>
                          </label>
                        </div>
                        <div class="col-12 col-sm-4">
                          <label class="form-label col-sm-12">
                            <select
                                id="roles"
                                v-model="item.role"
                                class="form-control border-bottom custom-select"
                                name="roles"
                                required
                            >
                              <option v-for="(role, index) in roles" :key="index" :value="role.id">{{ role.name }}</option>
                            </select>
                            <span>Rol *</span>
                          </label>
                        </div>

                      </div>

                      <div class="row mt-3">
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="phone"
                                v-model="item.phone"
                                type="number"
                                placeholder=" "
                                class="form-control border-bottom"
                                name="phone"
                                required
                                :disabled="showError"
                                :maxlength="maxLen"
                                @input="checkLength('phone', maxLen)"
                            />
                            <span>Teléfono *</span>
                          </label>
                        </div>
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="email"
                                v-model="item.email"
                                type="email"
                                placeholder=" "
                                class="form-control border-bottom"
                                name="email"
                                required
                                :maxlength="maxLen * 5"
                                @input="checkLength('email', maxLen * 5)"
                            />
                            <span>Correo Electrónico *</span>
                          </label>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                :type="showPassword ? 'text' : 'password'"
                                id="password"
                                v-model="item.password"
                                placeholder=" "
                                class="form-control border-bottom"
                                name="password"
                                required
                                :minlength="minLen"
                                :maxlength="maxLen * 2"
                                @input="checkLength('password', maxLen * 2); checkMinLength('password', minLen)"
                            />
                            <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="showPassword = !showPassword" style="position: absolute; right: 10px; top: 10px;"></i>
                            <span>Contraseña *</span>
                          </label>
                        </div>
                        <div class="col-12 col-sm-6">
                          <label class="form-label col-sm-12">
                            <input
                                id="repeatPassword"
                                v-model="item.repeatPassword"
                                :type="showPassword2 ? 'text' : 'password'"
                                placeholder=" "
                                class="form-control border-bottom"
                                name="repeatPassword"
                                required
                                :minlength="minLen"
                                :maxlength="maxLen * 2"
                                @input="checkLength('password', maxLen * 2); checkMinLength('password', minLen); validatePassword()"
                            />
                            <i :class="showPassword2 ? 'fa fa-eye-slash' : 'fa fa-eye'" @click="showPassword2 = !showPassword2" style="position: absolute; right: 10px; top: 10px;"></i>
                            <span>Confirmar Contraseña *</span>
                          </label>
                        </div>

                      </div>
                      <div class="row mt-3">
                      </div>
                      <div v-if="error !== '' || showError">
                        <div class="row" v-if="error != null || showError">
                          <material-alert
                              border="top"
                              close-icon="$cancel"
                              color="danger"
                              transition="scale-transition"
                              :value="alert"
                              dismissible
                          >
                            {{ error }}
                          </material-alert>
                        </div>
                      </div>
                      <div class="button-row d-flex mt-4">
                        <material-button
                            type="button"
                            color="danger"
                            variant="danger"
                            class="js-btn-send"
                            @click="getRouteUserList()"
                        >Cancelar</material-button
                        >
                        <material-button
                            type="button"
                            color="success"
                            variant="gradient"
                            class="ms-auto js-btn-send"
                            :disabled="!isFormValid || error !== '' || showError"
                            @click="createUser()"
                        >Aceptar</material-button
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";


export default {
  name: "NewUser",
  components: {
    MaterialAlert,
    MaterialButton,
  },
  data() {
    return {
      randomString: '',
      maxLen: 10,
      minLen: 8,
      showError: false,
      showAlert: false,
      showPassword: false,
      showPassword2: false,
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      error: '',
      ok: null,
      alert: false,
      roles: [],
      areas: [],
      inputType: 'password',
      formData: null,
      imageUrl: null,
      url_aws: 'https://s3-integration-store-qa.s3.amazonaws.com',
      item: {
        id: -1,
        username: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        area: null,
        role: null,
        password: '',
        repeatPassword: ''
      },
      errors: {
        id: -1,
        username: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        area: null,
        role: null,
        password: '',
        repeatPassword: ''
      }
    };
  },
  watch :{
    '$store.state.newIdUser': function (id) {
      this.item.id = id;
      console.log("el id del Usuario: ", this.item.id);
    },

    '$store.state.ok': function (value) {
      this.ok = true;
      if (this.formData?.file) {
        console.log('El campo file existe y tiene un valor');
        if (value){
          this.formData.append('id', this.item.id);
          this.$axios.post('/avatar_picture/', this.formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(async response => {
            console.log(response.data);
            this.show_text = true;
            this.message = response.data['message']
            this.isLoading = false
          }).catch(error => {
            console.error(error);
            this.alert = true;
            this.error = error
            this.isLoading = false
          });
          this.ok = true;
          setTimeout(this.getRouteUserList, 100);
        }
      } else {
        this.ok = true;
        setTimeout(this.getRouteUserList, 100);
      }
    },
    '$store.state.errorMessage': function (message) {
      this.error = message;
      this.alert = true;
    },
    '$store.state.rolesList': function (roles) {
      this.roles = roles;
    },
    '$store.state.areasList': function (areas) {
      console.log("Areas: ", areas);
      this.areas = areas;
    },
  },
  methods: {
    generateRandomString() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 4; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    checkMinLength(field, minLen) {
      if (field === 'phone' && (this.item[field].toString()).length < minLen ){
        this.error = `El campo debe tener más de ${minLen ? minLen: this.minLen} caracteres`;
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.error = '';
        }, 5000);
      } else if (this.item[field].length < (minLen ? minLen: this.minLen)) {
        this.error = `El campo debe tener más de ${minLen ? minLen: this.minLen} caracteres`;
        this.showError = true;
      }
    },
    checkLength(field, maxLen) {
      if (field === 'phone'){
        if (isNaN(this.item[field])){
          this.showError = true;
          this.error = `Número de Teléfono invalido, rectifiquelo`;
        }
        if ((this.item[field].toString()).length > maxLen){
          this.error = `El campo no puede tener más de ${maxLen ? maxLen: this.maxLen} caracteres`;
          this.showError = true;
        }
        setTimeout(() => {
          this.showError = false;
          this.error = '';
        }, 5000);
      }
      else if (this.item[field].length >= (maxLen ? maxLen: this.maxLen)) {
        this.showError = true;
        this.error = `El campo no puede tener más de ${maxLen ? maxLen: this.maxLen} caracteres`;
        console.log("error: ", field);
        console.log("error: ", this.error);
        console.log("error: ", this.showError);
      } else {
        this.showError = false;
        this.error = '';
      }
    },
    onButtonClick() {
      this.$refs.fileUpload.click();
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageUrl = URL.createObjectURL(file);
        this.formData = new FormData();
        this.formData.append('file', file);
      }
    },
    getRouteUserList(){
      this.$router.push({ path: "/users/user-list" })
    },
    textAlert() {
      return this.ok
          ? "Usuario creado satisfactoriamiente, cree otro si lo desea"
          : "Error al intentar crear un Usuario, revise los datos introducidos por favor";
    },
    colorAlert() {
      return this.ok ? "success" : "danger";
    },
    validatePassword(){
      if (this.item.password !== this.item.repeatPassword) {
        this.showError = true;
        this.error = 'Las contraseñas no coinciden';
        this.alert = true;
        console.log("password don't match");
      } else {
        this.error = '';
        this.alert = false;
        this.showError = false;
        console.log("password match");
      }
    },
    createUser() {
      this.$store.dispatch('actionsCreateUser', {
        axios: this.$axios,
        password: this.item.password,
        username: this.item.username,
        email: this.item.email,
        area: this.item.area,
        phone: this.item.phone,
        firstName: this.item.firstName,
        lastName: this.item.lastName,
        role: this.item.role,
      });
    },
  },
  computed: {
    isFormValid() {
      // this.showError = this.imageUrl === '';
      // this.error = 'Debe Seleccionar una imagen de perfil del usuario (Avatar)';
      return Object.values(this.item).every(field => field !== '') && !this.showError;
    }
  },
  async mounted() {
    this.randomString = this.generateRandomString();
    this.$store.dispatch('actionsGetAllRoles', {'axios': this.$axios});
    this.$store.dispatch('actionsGetAllAreas', {'axios': this.$axios});
    this.roles = await this.$store.getters.getAllRoles;
    setTimeout(() => {
      this.inputType = 'text';
    }, 50);
  }
};
</script>
<style>
.form-label {
  position: relative;
  display: inline-block;
}


.form-label span {
  position: absolute;
  left: 5px;
  top: 0;
  transition: 0.2s;
  pointer-events: none;
}

.form-label input:focus + span,
.form-label input:not(:placeholder-shown) + span {
  transform: translateY(-25px);
  font-size: 0.8em;
}
.custom-select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23aaa" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>');
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 8px 10px;
}
.form-label select:focus + span,
.form-label select:not(:placeholder-shown) + span {
  transform: translateY(-15px);
  font-size: 0.8em;
}
</style>
