import store from "@/store";

export default class PrintingProfile {
    static async all(axios) {
        const response = await axios?.post(
            "/graphql/",
            `query{
              printingProfileList{
                results{
                  id
                  labelSize
                  leafMargins
                  name
                  separationBetweenLabels
                  sheetPosition
                  sheetSize
                  user{
                    username
                  }
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        return response.data.data?.printingProfileList.results.reverse();
    }

    static async retrieve(axios, id) {
        const response = await axios
            .post(
                "/graphql/",
                `query{
                  printingProfileRetrieve(id:${id}){
                    id
                    labelSize
                    leafMargins
                    name
                    separationBetweenLabels
                    sheetPosition
                    sheetSize
                    user{
                      id
                      username
                    }
                  }
                }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            )
            .catch(function (error) {
                if (error.response) {
                    console.log("primer strike");
                    return null;
                } else if (error.request) {
                    console.log("segudo strike");

                    return null;
                } else {
                    console.log("3er strike");
                    return null;
                }
            });

        if (response) {
            if (
                response.data.data.printingProfileRetrieve
            ) {
                response.data.data.printingProfileRetrieve.user = response.data.data.printingProfileRetrieve?.user.id
            }
            return response.data.data.printingProfileRetrieve;
        }
    }

    static async edit(
        axios,
        id,
        name,
        sheetSize,
        labelSize,
        leafMargins,
        sheetPosition,
        separationBetweenLabels,
        user
    ) {
        // let idEmpoyment = Utils.generateString()
        // idEmpoyment: "${idEmpoyment}",

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                  updatePrintingProfile(newPrintingprofiles:{
                        id:${id}
                        name:"${name}",
                        sheetSize:${sheetSize},
                        labelSize:"${labelSize}",
                        leafMargins:"${leafMargins}",
                        sheetPosition:${sheetPosition},
                        separationBetweenLabels:"${separationBetweenLabels}",
                        user:${user},
                      }){
                        printingprofiles{
                          id
                          labelSize
                          leafMargins
                          name
                          separationBetweenLabels
                          sheetPosition
                          sheetSize
                          user{
                            id
                            username
                          }
                        }
                        ok
                        errors{
                          field
                          messages
                        }
                      }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined && !response.data.errors) {
            if (response.data.data.updatePrintingProfile.ok) {
                return response.data.data.updatePrintingProfile.ok;
            }
            else if (response.data.data.updatePrintingProfile.errors) {
                let errores = response.data.data.updatePrintingProfile.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.updatePrintingProfile.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            }
        } else if (response.data.errors){
            console.log("lo que tenemos errors: ", response.data.errors[0].message);

            await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
            return null;
        }
        else {
            return null;
        }
    }

    static async create(
        axios,
        name,
        sheetSize,
        labelSize,
        leafMargins,
        sheetPosition,
        separationBetweenLabels,
        user
    ) {
        // let idEmpoyment = Utils.generateString()
        // idEmpoyment: "${idEmpoyment}",

        const response = await axios?.post(
            "/graphql/",
            `mutation{
                createPrintingProfile(newPrintingprofiles:{
                        name:"${name}",
                        sheetSize:${sheetSize},
                        labelSize:"${labelSize}",
                        leafMargins:"${leafMargins}",
                        sheetPosition:${sheetPosition},
                        separationBetweenLabels:"${separationBetweenLabels}",
                        user:${user},
                      }){
                        printingprofiles{
                          id
                          labelSize
                          leafMargins
                          name
                          separationBetweenLabels
                          sheetPosition
                          sheetSize
                          user{
                            id
                            username
                          }
                        }
                        ok
                        errors{
                          field
                          messages
                        }
                      }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                store.dispatch('actionsSetErrorMessage', error.response);
                console.log("primer strike");
                return null;
            } else if (error.request) {
                console.log("segudo strike");
                store.dispatch('actionsSetErrorMessage', error.request);

                return null;
            } else {
                console.log("3er strike");
                store.dispatch('actionsSetErrorMessage', '3er strike');

                return null;
            }
        });
        if (response !== null && response.data !== undefined && !response.data.errors) {
            if (response.data.data.createPrintingProfile.ok) {
                return response.data.data.createPrintingProfile.ok;
            }
            else if (response.data.data.createPrintingProfile.errors) {
                let errores = response.data.data.createPrintingProfile.errors;
                let mensajes = [];

                for (let i = 0; i < errores.length; i++) {
                    let error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.createPrintingProfile.errors);
                await store.dispatch('actionsSetErrorMessage', mensajes);
                return null;
            }
        } else if (response.data.errors){
            console.log("lo que tenemos errors: ", response.data.errors[0].message);

            await store.dispatch('actionsSetErrorMessage', response.data.errors[0].message);
            return null;
        }
        else {
            return null;
        }
    }
    static async delete(axios, id) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deletePrintingProfile ($id: ID!){
                      deletePrintingProfile(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deletePrintingProfile.ok;
    }
}
