<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="brooklynAlice"
          :nav-text="getFullName()"
          :class="getRoute() === 'brooklynAlice' ? 'active' : ''"
        >
          <template #icon>
            <img :src="getAvatar()" class="avatar" alt=""/>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Informacion General del Perfil' }"
                mini-icon="MP"
                text="Mi Perfil"
              />
              <sidenav-item
                :to="{ name: 'Cerrar Sesión' }"
                mini-icon="CS"
                text="Cerrar Sesión"
                @click.stop="show_alert"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="dashboardsExamples"
          nav-text="Dashboards"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">dashboard</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Dashboards' }"
                mini-icon="E"
                text="Estadísticas"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          Vistas
        </h6>
      </li>
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Usuarios"
          :class="getRoute() === 'pages' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">image</i>
          </template>
          <template #list>
            <sidenav-item
                :to="{ name: 'Nuevo Usuario' }"
                mini-icon="N"
                text="Nuevo Usuario"
            />
              <sidenav-item
                  :to="{ name: 'Lista de Usuario' }"
                  mini-icon="LU"
                  text="Lista de Usuarios"
              />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'order' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="ecommerceExamples"
          nav-text="Órdenes"
          :class="getRoute() === 'ecommerce' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">shopping_basket</i>
          </template>
          <template #list>
            <sidenav-item
                :to="{ name: 'Listado de Órdenes' }"
                mini-icon="O"
                text="Listado Órdenes"
            />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" :class="getRoute() === 'roles' ? 'active' : ''">
        <sidenav-collapse
            collapse-ref="rolesExamples"
            nav-text="Roles"
            :class="getRoute() === 'roles' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">shopping_basket</i>
          </template>
          <template #list>
            <sidenav-item
                :to="{ name: 'Nuevo Rol' }"
                mini-icon="NR"
                text="Nuevo Rol"
            />
            <sidenav-item
                :to="{ name: 'Listado de Roles' }"
                mini-icon="LR"
                text="Listado Roles"
            />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" :class="getRoute() === 'area' ? 'active' : ''">
        <sidenav-collapse
            collapse-ref="areaExamples"
            nav-text="Áreas"
            :class="getRoute() === 'area' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">shopping_basket</i>
          </template>
          <template #list>
            <sidenav-item
                :to="{ name: 'Nueva Área' }"
                mini-icon="NA"
                text="Nueva Área"
            />
            <sidenav-item
                :to="{ name: 'Listado de Áreas' }"
                mini-icon="LA"
                text="Listado Áreas"
            />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'report' ? 'active' : ''">
        <sidenav-collapse
            collapse-ref="reportExamples"
            nav-text="Reportes"
            :class="getRoute() === 'report' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">shopping_basket</i>
          </template>
          <template #list>
            <sidenav-item
                :to="{ name: 'Lista de Incidencias' }"
                mini-icon="IN"
                text="Incidencias"
            />
            <sidenav-item
                :to="{ name: 'Lista de Impresiones' }"
                mini-icon="IM"
                text="Impresiones"
            />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'printingProfile' ? 'active' : ''">
        <sidenav-collapse
            collapse-ref="printingProfileExamples"
            nav-text="Perfiles de Impresión"
            :class="getRoute() === 'printingProfile' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">shopping_basket</i>
          </template>
          <template #list>
            <sidenav-item
                :to="{ name: 'Nuevo Perfil de Impresión' }"
                mini-icon="NPI"
                text="Nuevo Perfil de Impresión"
            />
            <sidenav-item
                :to="{ name: 'Listado de Perfil de Impresión' }"
                mini-icon="LPI"
                text="Perfiles de Impresión"
            />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse
  },
  data() {
    return {
      title: "S3 Integración",
      controls: "dashboardsExamples",
      isActive: "active",
      showModal: false,
      url_aws: 'https://s3-integration-store-qa.s3.amazonaws.com'
    };
  },
  computed: {
    ...mapState(["isRTL"])
  },
  methods: {
    ...mapActions(['actionsLogout']),
    getFullName() {
      let currentUser = this.$store.getters.getCurrentUserAuth;
      if (
          typeof currentUser === 'object'
          && currentUser !== null
          && currentUser !== 'undefined'
          && currentUser !== undefined
      ) {
        return currentUser.firstName + " " + currentUser.lastName;
      } else if (
          typeof currentUser === 'string'
          && currentUser !== 'undefined'
      ) {
        let currentUserJson = JSON.parse(currentUser);
        return currentUserJson.firstName + " " + currentUserJson.lastName;
      } else {
        console.log('No user data available');
        // this.$router.push({ path: "/login" });
        return '';
      }
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    show_alert(){
      this.$router.push({ path: "/logout" });
      // let result = confirm("¿Estás seguro de que quieres cerrar session?");
      // if (result) {
      //   this.logout();
      //   console.log("El usuario aceptó.");
      // } else {
      //   console.log("El usuario canceló.");
      // }
    },
    logout() {
      this.refresToken = this.$store.getters.getRefreshToken;
      this.actionsLogout({
        'axios': this.$axios,
        refreshToken: this.refresToken,
        store: this.$store }
      );
      this.$router.push({ path: "/" });
    },
    getAvatar() {
      let currentUser = this.$store.getters.getCurrentUserAuth;
      if (
          typeof currentUser === 'object'
          && currentUser !== null
          && currentUser !== 'undefined'
          && currentUser !== undefined
      ) {
        console.log("avatar url: ", this.url_aws+'/Avatar/'+currentUser.avatar);
        return this.url_aws+'/Avatar/'+currentUser.avatar;
      } else if (
          typeof currentUser === 'string'
          && currentUser !== 'undefined'
      ) {
        let currentUserJson = JSON.parse(currentUser);
        console.log("avatar url: ", this.url_aws+'/Avatar/'+currentUserJson.avatar);
        return this.url_aws+'/Avatar/'+currentUserJson.avatar;
      } else {
        console.log('No user data available');
        return '';
      }
    },
  },
  mounted() {
    this.$store.dispatch("actionsCurrentUser", { axios: this.$axios });

    if (
        this.$store.getters.getToken === null ||
        this.$store.getters.getToken === "null"
    ) {
      this.$router.push({ path: "/login" });
    }
  },
};
</script>

