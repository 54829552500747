export default class Order {
    static async all(axios, page, pageSize) {
        const response = await axios?.post(
            "/graphql/",
            `query{
              orderList(page: ${page}, pageSize: ${pageSize}){
                results{
                    id
                    poNumber
                    asin
                    deliveryStatus
                    orderDate
                    carrierTracking
                    carrierName
                    itemQuantity
                    orderNumber
                    carrierTracking
                    title
                }
              }
            }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        return response.data.data?.orderList.results;
    }
    static async retrieve(axios, id) {
        const response = await axios?.post(
            "/graphql/",
            `{
              orderRetrieve(id: ${id}) {
                id
                poNumber
                asin
                deliveryStatus
                orderDate
                carrierTracking
                carrierName
                itemQuantity
                carrierTracking
                title
                orderNumber
                orderId
                poNumber
                itemQuantity
                shippingAddress
              }
            }
            `,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        return response.data.data?.orderRetrieve;
    }
}
